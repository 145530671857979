/** @jsx jsx */
import { jsx } from "theme-ui";

import DLWLayout from "../../layouts/dlw-mobile";
import MyAbhyasis from "../../components/MyAbhyasis";

function MyAbhyasisPage() {
  return (
    <DLWLayout>
      <MyAbhyasis />
    </DLWLayout>
  );
}

export default MyAbhyasisPage;
