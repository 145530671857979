/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import {
  doFormatShortDate,
  doFormatTitle,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";

const colDefns = [
  [
    "Name",
    (r) => (
      <AbhyasiEntity
        abhyasiId={r.ref}
        display={doFormatTitle(r.name)}
        item={r}
      />
    ),
  ],
  ["Center", (r) => doFormatTitle(r.srcm_group?.name)],
  ["Stage", (r) => doFormatTitle(r.abhyas_stage.replace("NEW_", " "))],
  [
    "Started Practice",
    (r) => r.date_of_joining && doFormatShortDate(r.date_of_joining),
  ],
];

const MyAbhyasis = () => {
  const { srcmProfile } = useAuth;

  return (
    <div sx={{ fontSize: "0.8rem" }}>
      <Text variant="title">Practitioners assigned to me</Text>

      <SrcmSearchPage
        reduxKey="my-practitioners"
        apiParams={{
          api: `/api/v2/me/myabhyasis/`,
          client: "profileClient",
        }}
        defaultKey="ref"
        defaultValue={srcmProfile?.abhyasiId}
        keyOptions={[
          { name: "ref", label: "Abhyasi Id" },
          { name: "name__istartswith", label: "Name Starts With" },
        ]}
        colDefns={colDefns}
      />
    </div>
  );
};

export default MyAbhyasis;
